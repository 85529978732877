<template>
	<div v-if="authed">
		<splitpanes horizontal class="default-theme" style="height:calc(100vh - 52px)" @resized="setTableHeight" v-resize="setTableHeight">
			<pane min-size="20" size="35" ref="toppane">
				<v-data-table :headers="headers" :items="items" item-key="_id" v-model="selected"
					:options.sync="options" :server-items-length="totalItems"
					:footer-props="{ itemsPerPageOptions: [10, 30, 50] }" fixed-header :height="tableheight"
					:loading="loading" loading-text="加载中..." show-select single-select>
				<template v-slot:top>
					<div class="d-flex flex-wrap" style="gap:16px;row-gap:0;" ref="tabletopbar">
						<v-btn-toggle mandatory dense v-model="filter" @change="fetchData">
							<v-btn>待开单</v-btn>
							<v-btn>已开单</v-btn>
							<v-btn>3个月内待复诊</v-btn>
							<v-btn :disabled="!canExport">过期未复诊</v-btn>
							<!--<v-btn>18个月内复诊</v-btn>-->
							<v-btn :disabled="!canExport">复诊已手术</v-btn>
							<v-btn :disabled="!canExport">复诊已下单</v-btn>
						</v-btn-toggle>
						<v-text-field placeholder="姓名" v-model="pname" dense clearable hide-details style="flex: 0 1 auto;max-width:150px;"
							append-icon="search" @click:append="fetchData" @keyup.enter.native="fetchData"/>
						<v-menu v-model="menu1" :close-on-content-click="false" :nudge-right="10"
							transition="scale-transition" offset-y min-width="290px">
							<template v-slot:activator="{ on, attrs }">
								<v-text-field v-model="pdatestext" placeholder="期望复诊日期" readonly v-bind="attrs" style="flex: 0 1 auto;width:240px;"
									v-on="on" dense hide-details append-icon="mdi-close" @click:append="clearpdates"></v-text-field>
							</template>
							<v-date-picker v-model="pdates" @change="applypdates" range no-title scrollable></v-date-picker>
						</v-menu>
						<v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="10"
							transition="scale-transition" offset-y min-width="290px">
							<template v-slot:activator="{ on, attrs }">
								<v-text-field v-model="operdatestext" :placeholder="filter === 6 ? '下单日期' : '手术日期'" readonly v-bind="attrs" style="flex: 0 1 auto;width:240px;"
									v-on="on" dense hide-details append-icon="mdi-close" @click:append="clearOperdates"></v-text-field>
							</template>
							<v-date-picker v-model="operdates" @change="applyOperdates" range no-title scrollable></v-date-picker>
						</v-menu>
						<v-btn v-if="canExport" color="primary" @click.stop="exportXLS">导出</v-btn>
					</div>
					<v-divider/>
				</template>
				<template v-slot:item.progress.order="{ item }">
					{{formatTime(item.progress.order)}}
				</template>
				<template v-slot:item.progress.operative="{ item }">
					{{formatTime(item.progress.operative)}}
				</template>
				<template v-slot:item.progress.pathology="{ item }">
					{{formatTime(item.progress.pathology)}}
				</template>
				<template v-slot:item.progress.subsequent="{ item }">
					{{formatTime(item.progress.subsequent)}}
				</template>
				<template v-slot:item.progress.subsequentSent="{ item }">
					{{formatTime(item.progress.subsequentSent)}}
				</template>
				<template v-slot:item.subsequent.estTimew="{ item }">
					{{item.subsequent ? formatDate(item.subsequent.estTimew) : ''}}
				</template>
				<template v-slot:item.subsequent.estTimec="{ item }">
					{{item.subsequent ? formatDate(item.subsequent.estTimec) : ''}}
				</template>
				<template v-slot:item.idcard="{ item }">
					{{getMaskedIdcard(item)}}
				</template>
				<template v-slot:item.gender="{ item }">
					{{getGender(item)}}
				</template>
				<template v-slot:item.age="{ item }">
					{{getAge(item)}}
				</template>
				<template v-slot:item.opts="{ item }">
					{{getOptsStr(item)}}
				</template>
				</v-data-table>
			</pane>
			<pane min-size="20" size="65" v-if="(selected.length > 0)">
				<div style="max-height: 100%" class="overflow-y-auto pl-4 pt-4">
					<div>
						<v-btn rounded color="primary" width="140" class="mt-0 mb-4" @click.stop="genSS">编辑复诊单</v-btn>
						<v-btn rounded color="primary" width="140" class="mt-0 mb-4 ml-4" @click.stop="confirmSent(true)" :loading="loading"
							:disabled="!selected[0] || !selected[0].subsequent || !!selected[0].progress.subsequentSent">确认已发送</v-btn>
						<v-btn rounded color="primary" width="140" class="mt-0 mb-4 ml-4" @click.stop="confirmSent(false)" :loading="loading"
							:disabled="!selected[0] || !selected[0].subsequent || !!selected[0].progress.subsequentSent">确认不发送</v-btn>
					</div>
					<div v-if="selected[0] && selected[0].subsequent" style="width:500px;">
						<v-text-field filled readonly label="诊断" :value="selected[0].subsequent.according"/>
						<v-row>
							<v-col><v-text-field filled readonly label="胃镜复诊周期" :value="selected[0].subsequent.periodw"/></v-col>
							<v-col><v-text-field filled readonly label="肠镜复诊周期" :value="selected[0].subsequent.periodc"/></v-col>
						</v-row>
						<v-text-field filled readonly label="治疗建议" :value="selected[0].subsequent.tx"/>
						<v-text-field filled readonly label="检查建议" :value="selected[0].subsequent.exam"/>
					</div>
	            </div>
			</pane>
		</splitpanes>
		<subsequent v-model="subsequentDlg" :item="selected[0]" :readonly="!canEdit" :mode="showmode"/>
    </div>
    <div v-else>
        无此权限
    </div>
</template>

<script>
	import XLSX from 'xlsx-js-style'
    import {formatTime, formatDate, calculateAge} from '../utils'
    import { Splitpanes, Pane } from 'splitpanes'
    import 'splitpanes/dist/splitpanes.css'
	import store from '../store.js'
	import Subsequent from '../components/Subsequent.vue'

    export default {
        data() {
            return {
                authed: false,
				canEdit: false,
				canExport: false,
                headers: [
                    {text:'病案号', value:'pid', width:100},
                    {text:'姓名', value:'pii.username', width:100},
                    {text:'性别', value:'gender', width:80},
                    {text:'年龄', value:'age', width:80},
                    {text:'术后等级', value:'endoscopyResult.followupLevel', width:100},
                    {text:'下单时间', value:'progress.order', width:180},
                    {text:'手术完成时间', value:'progress.operative', width:180},
                    {text:'解读完成时间', value:'progress.pathology', width:180},
                    {text:'开复诊单时间', value:'progress.subsequent', width:180},
                    {text:'发送复诊单时间', value:'progress.subsequentSent', width:180},
                    {text:'复查胃镜期望', value:'subsequent.estTimew', width:160},
                    {text:'复查肠镜期望', value:'subsequent.estTimec', width:160},
                    {text:'活检', value:'endoscopyResult.biopsy', width:120},
                    {text:'息肉', value:'endoscopyResult.polyp', width:120},
                    {text:'套餐内容', value:'opts', width:240, sortable: false},
                    {text:'电话', value:'pii.phone', width:100},
                    {text:'证件号', value:'idcard', width:120},
                    {text:'健康顾问', value:'consultantName', width:100},
                    {text:'订单号', value:'_id', width:100},
                    {text:'诊所', value:'region', width:100},
                ],
                items: [],
                loading: false,
				selected: [],
				filter: 0,
				options: {sortBy:['progress.operative'], sortDesc:[false]},
				totalItems: 0,
				pname: '',
				subsequentDlg: false,
				showmode: 0,
				menu1: false,
				pdates: [],
				menu2: false,
				operdates: [],
				tableheight: undefined,
            }
        },
		computed: {
			pdatestext() {
				return this.pdates.join(' ~ ');
			},
			operdatestext() {
				return this.operdates.join(' ~ ');
			},
		},
        mounted() {
			this.authed = this.$hasPrivilege(['复诊开单','复诊管理','健康顾问']);
			if (!this.authed) return;
			this.canEdit = this.$hasPrivilege(['复诊开单']);
			this.canExport = this.$hasPrivilege(['复诊管理']);
			this.formatTime = formatTime;
			this.formatDate = formatDate;
			this.$watch(()=>store.currentRegion, this.fetchData);
			this.fetchData();
        },
        methods: {
			setTableHeight() {
				if (!this.$vuetify.breakpoint.mobile && this.selected.length === 0) {
					this.tableheight = undefined;
					return;
				}
				const tableFooterHeight = 59;
				setTimeout(() => {
					const ttbh = this.$refs.tabletopbar.getBoundingClientRect().height;
					const paneHeight = this.$refs.toppane.$el.getBoundingClientRect().height;
					this.tableheight = paneHeight - tableFooterHeight - ttbh;
					if (this.selected.length > 0) this.$nextTick(() => this.$vuetify.goTo(".v-data-table__selected", { container: ".v-data-table__wrapper" }) );
				}, 200)
			},
			async applypdates() {
				this.menu1 = false;
				this.fetchData();
			},
			async clearpdates() {
				this.pdates = [];
				this.fetchData();
			},
			async applyOperdates() {
				this.menu2 = false;
				this.fetchData();
			},
			async clearOperdates() {
				this.operdates = [];
				this.fetchData();
			},
			getFilter() {
				const est = new Date(Date.now() + 90 * 24 * 3600 * 1000);
				const db = this.$tcbapp.database();
				const _ = db.command;
				const filters = [
					_.and([{'subsequent':_.exists(false)}, {endoscopyResult:_.exists(true)}]),
					{'subsequent':_.exists(true)},
					_.or([{'subsequent.estTimew':_.lt(est)}, {'subsequent.estTimec':_.lt(est)}]),
					{},
					{},
					{},
				];
				let filter = this.pname ? [{'pii.username':this.pname}] : [];
				if (this.$hasPrivilege(['复诊管理'])) {
					//admin也有此权限
				} else if (this.$hasPrivilege('健康顾问')) {
					const u = this.$getCurrentUser();
					filter.push({consultantId: u.consultantId});
				}
				if (this.pdates.length === 2) {
					const d1 = new Date(this.pdates[0] + 'T00:00:00+08:00');
					const d2 = new Date(this.pdates[1] + 'T23:59:59+08:00');
					filter.push(_.or([
						_.and([{'subsequent.estTimew':_.gt(d1)}, {'subsequent.estTimew':_.lt(d2)}]),
						_.and([{'subsequent.estTimec':_.gt(d1)}, {'subsequent.estTimec':_.lt(d2)}])
					]));
				}
				if (this.operdates.length === 2) {
					const d1 = new Date(this.operdates[0] + 'T00:00:00+08:00');
					const d2 = new Date(this.operdates[1] + 'T23:59:59+08:00');
					filter.push(
						_.and([{'progress.operative':_.gt(d1)}, {'progress.operative':_.lt(d2)}]),
					);
				}
				const f = filter.concat(filters[this.filter]);
				return f;
			},
			async fetchData() {
				if (this.filter === 3) {
					this.totalItems = -1;
					this.items = await this.fetchExpired();
					return;
				} else if (this.filter === 4) {
					this.totalItems = -1;
					this.items = await this.fetchDone();
					return;
				} else if (this.filter === 5) {
					this.totalItems = -1;
					this.items = await this.fetchStOrdered();
					return;
				}
				const f = this.getFilter();
				const db = this.$tcbapp.database();
				const _ = db.command;
				this.loading = true;
				try {
                    const countRes = await db.collection('wp2order').where(_.and(f)).count();
					this.totalItems = countRes.total;
					const { sortBy, sortDesc, page, itemsPerPage } = this.options;
					const res = await db.collection('wp2order').where(_.and(f)).orderBy(sortBy[0]||'_', sortDesc[0] ? 'desc' : 'asc')
						.skip((page-1) * itemsPerPage).limit(itemsPerPage)
						.get();
					this.items = res.data;
				} catch(err) {
					console.error(err);
				}
				this.loading = false;
			},
			async fetchExpired() {
				const db = this.$tcbapp.database();
				const _ = db.command;
				const $ = _.aggregate;
				const today = new Date().toISOString();
				this.loading = true;
				try {
//					const { sortBy, sortDesc, page, itemsPerPage } = this.options;
					const res = await db.collection('wp2order')
						.aggregate()
						.match({endoscopyResult:_.exists(true)})
						.sort({'progress.order':-1})
						.group({
							_id: '$pii.idcard',
							maxOrderTime: $.max('$progress.order'),
							maxSSTime: $.max('$progress.subsequent'),
							maxStW: $.max('$subsequent.estTimew'),
							maxStC: $.max('$subsequent.estTimec'),
							rec: $.first('$$ROOT'),
						})
						.match(_.or(
							_.expr($.and([
							    $.lte(['$rec.subsequent.periodw', 18]),			//18个月以上的忽略
								$.not($.eq(['$maxStW', null])),
								$.lt(['$maxStW', $.dateFromString({dateString:today})]),
								$.gt(['$maxSSTime', '$maxOrderTime'])
							])),
							_.expr($.and([
    							$.lte(['$rec.subsequent.periodc', 18]),			//18个月以上的忽略
								$.not($.eq(['$maxStC', null])),
								$.lt(['$maxStC', $.dateFromString({dateString:today})]),
								$.gt(['$maxSSTime', '$maxOrderTime'])
							])),
						))
						.replaceRoot({newRoot:'$rec'})
						.limit(50)
						.end();
					return res.data;
				} catch(err) {
					console.error(err);
				} finally {
					this.loading = false;
				}
			},
			async fetchStOrdered() {
				const db = this.$tcbapp.database();
				const _ = db.command;
				const $ = _.aggregate;
				let d1, d2;
				if (this.operdates.length === 2) {
					d1 = this.operdates[0] + 'T00:00:00+08:00';
					d2 = this.operdates[1] + 'T23:59:59+08:00';
				} else {
					d1 = "2010/01/01";
					d2 = "2039/12/31";
				}
				this.loading = true;
				try {
					const res = await db.collection('wp2order')
						.aggregate()
						.match({hide:_.neq(true), category:_.neq('clinic')})
						.sort({'progress.order':-1})
						.group({
							_id: '$pii.idcard',
							maxOrderTime: $.max('$progress.order'),
							maxOperTime: $.max('$progress.operative'),
							rec: $.first('$$ROOT'),
						})
						.match(_.and(_.expr($.not($.eq(['$maxOperTime', null]))), _.expr($.gt(['$maxOrderTime', '$maxOperTime']))))
						.match(_.and([
							_.expr($.gt(['$maxOrderTime', $.dateFromString({dateString:d1})])),
							_.expr($.lt(['$maxOrderTime', $.dateFromString({dateString:d2})])),
						]))
						.replaceRoot({newRoot: $.mergeObjects([ '$rec', '$$ROOT' ])})
						.limit(50)
						.end();
					return res.data;
				} catch(err) {
					console.error(err);
				} finally {
					this.loading = false;
				}
			},
			async fetchDone() {
				const db = this.$tcbapp.database();
				const _ = db.command;
				const $ = _.aggregate;
				let d1, d2;
				if (this.operdates.length === 2) {
					d1 = this.operdates[0] + 'T00:00:00+08:00';
					d2 = this.operdates[1] + 'T23:59:59+08:00';
				} else {
					d1 = "2010/01/01";
					d2 = "2039/12/31";
				}
				this.loading = true;
				try {
					const res = await db.collection('wp2order')
						.aggregate()
						.match({endoscopyResult:_.exists(true)})
						.sort({'progress.operative':-1})
						.group({
							_id: '$pii.idcard',
							maxOperTime: $.max('$progress.operative'),
							minOperTime: $.min('$progress.operative'),
							maxStW: $.max('$subsequent.estTimew'),
							maxStC: $.max('$subsequent.estTimec'),
							rec: $.first('$$ROOT'),
						})
						.match(_.expr($.gt(['$maxOperTime', '$minOperTime'])))
						.match(_.and([
							_.expr($.gt(['$maxOperTime', $.dateFromString({dateString:d1})])),
							_.expr($.lt(['$maxOperTime', $.dateFromString({dateString:d2})])),
						]))
						.match(_.or(
							_.expr($.and([
								$.not($.eq(['$maxStW', null])),
//								$.lt(['$maxStW', '$maxOperTime'])
							])),
							_.expr($.and([
								$.not($.eq(['$maxStC', null])),
//								$.lt(['$maxStC', '$maxOperTime'])
							])),
						))
						.replaceRoot({newRoot:'$rec'})
						.limit(50)
						.end();
					return res.data;
				} catch(err) {
					console.error(err);
				} finally {
					this.loading = false;
				}
			},
			async fetchAll() {
				const MAX_LIMIT = 100;
				const f = this.getFilter();
				const db = this.$tcbapp.database();
				const _ = db.command;
				const countResult = await db.collection('wp2order').where(_.and(f)).count();
				const total = countResult.total;
				const batchTimes = Math.ceil(total / MAX_LIMIT);
				const tasks = [];
				for (let i = 0; i < batchTimes; i++) {
					const promise = db.collection('wp2order').where(_.and(f)).skip(i * MAX_LIMIT).limit(MAX_LIMIT).get();
					tasks.push(promise);
				}
				const res = (await Promise.all(tasks)).reduce((acc, cur) => {
					return {
						data: acc.data.concat(cur.data),
						errMsg: acc.errMsg,
					}
				});
				return res.data;
			},
			async exportXLS() {
				const header = [[
					'姓名',
					'性别',
					'年龄',
					'下单时间',
					'手术完成时间',
					'解读完成时间',
					'开复诊单时间',
					'复查胃镜期望',
					'复查肠镜期望',
					'活检',
					'息肉',
					'套餐内容',
					'电话',
					'证件号',
					'健康顾问',
				]];
				let res = [];
				if (this.filter === 3) {
					res = await this.fetchExpired();
				} else if (this.filter === 4) {
					res = await this.fetchDone();
				} else if (this.filter === 5) {
					res = await this.fetchStOrdered();
				} else {
					res = await this.fetchAll();
				}
				const data = res.map(x => [
					x.pii.username,
					x.pii.gender,
					calculateAge(x.pii.dob),
					formatTime(x.progress.order),
					formatTime(x.progress.operative),
					formatTime(x.progress.pathology),
					formatTime(x.progress.subsequent),
					x.subsequent ? formatDate(x.subsequent.estTimew) : '',
					x.subsequent ? formatDate(x.subsequent.estTimec) : '',
					x.endoscopyResult?.biopsy || '',
					x.endoscopyResult?.polyp || '',
					x.opts.GIE + '/' + x.opts.ANES + '/' + x.opts.PRO,
					x.pii.phone,
					x.pii.idcard,
					x.consultantName,
				]);
				const t = '复检';
				const filename = t + ".xlsx";
				const ws_name = t;
				const wb = XLSX.utils.book_new(), ws = XLSX.utils.aoa_to_sheet(header.concat(data));
				XLSX.utils.book_append_sheet(wb, ws, ws_name);
				XLSX.writeFile(wb, filename);
			},
			getMaskedIdcard(item) {
				return item.pii.idcard.replace(/^(.{3})(?:\d+)(.{4})$/,  "\$1****\$2");
			},
            getGender(item) {
                return item.pii.gender;
            },
            getAge(item) {
                return calculateAge(item.pii.dob);
            },
            getOptsStr(item) {
				return item.opts.GIE + '/' + item.opts.ANES + '/' + item.opts.PRO;
			},
			genSS() {
//				this.showmode = this.filter === 4 ? 1 : 0;
				this.subsequentDlg = true;
			},
			async confirmSent(sent) {
				const id = this.selected[0]._id;
				this.loading = true;
				try {
					const res = await this.$tcbapp.callFunction({name:"wp2mp",
						data:{
							funcname:'sendSubsequent',
							data: {
								id,
								sent,
							}
						}
					});
					await this.fetchData();
					this.selected = [this.items.find(x => x._id === id)];
				} catch(err) {
					console.error(err);
					this.$dialog.message.error('确认失败');
				}
				this.loading = false;
			},
		},
		watch: {
			options: {
				handler () {
					this.fetchData()
				},
				deep: true,
			},
			selected(newitem, olditem) {
				if (newitem.length === 0 || !newitem[0].subsequent) return;
				const s = newitem[0].subsequent;
				/*
				this.periodw = s.periodw;
				this.periodc = s.periodc;
				this.tx = s.tx;
				this.exam = s.exam;
				*/
			}
		},
        components: { Splitpanes, Pane, Subsequent }
    }
</script>

<style>
.splitpanes.default-theme .splitpanes__pane {
    background-color: white;
}
.splitpanes.default-theme .splitpanes__splitter {
    background-color: #eee;
}
.splitpanes.default-theme .splitpanes__splitter:after,
.splitpanes.default-theme .splitpanes__splitter:before {
    background-color: rgba(0, 0, 0, .25);
}
.default-theme.splitpanes--horizontal>.splitpanes__splitter,
.default-theme .splitpanes--horizontal>.splitpanes__splitter {
    height: 8px;
}
.default-theme.splitpanes--horizontal>.splitpanes__splitter:after,
.default-theme .splitpanes--horizontal>.splitpanes__splitter:after,
.default-theme.splitpanes--horizontal>.splitpanes__splitter:before,
.default-theme .splitpanes--horizontal>.splitpanes__splitter:before {
    width: 60px;
    height: 1.5px
}
</style>